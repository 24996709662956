<template>
  <div>
    결제창 test
    <button class='block' @click='getAllProducts'> getAllProducts </button>
    <div v-for='product in products'
      :key='`product-${product.id}`'>{{ product }}
      <button class='block' @click="confirmBuyProduct(product, 'iamport-nice')"> nice request </button> <!-- korean card-->
      <button class='block' @click="confirmBuyProduct(product, 'iamport-eximbay')"> eximbay request </button> <!-- non-korean card-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'PaymentTest',
  computed: {
    ...mapState('products', [
      'products',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
  },
  methods: {
    ...mapActions('products', [
      'getAllProducts'
    ]),
    ...mapActions('orders', [
      'createOrder'
    ]),
    confirmBuyProduct (product, pg) {
      this.$confirm(`Do you want to buy ${product.name}?`, 'Info', {
        confirmButtonText: 'BUY',
        cancelButtonText: 'No',
        type: 'Info'
      }).then(() => {
        this.createOrder({product: {
            product_id: product.id,
            payment_type: pg,
            event_id: this.showingEventId}
          }).then(order => {
            let routeData = this.$router.resolve({name: 'IamportPayment', query: {
              pg: pg,
              amount: order.price.amount,
              orderId: order.hashedOrderId,
              orderName: order.orderName,
              customerName: order.customerName 
            }})
            window.open(routeData.href, '_blank')
        }).catch(() => {
          this.$alert('결제가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch(() => {
        // cancel - do nothing
      })
    }
  }
}
</script>
